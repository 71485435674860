
//import React from 'react';
import { BrowserRouter as Router, Routes, Route , useLocation, useNavigate} from 'react-router-dom';
import ReportPage from './components/ReportPage';

import React, { useState, useEffect } from "react";
// import {
//   BrowserRouter as Router,
//   Routes,
//   Route,
//   useNavigate,
// } from "react-router-dom";
import Chatbot from "./components/Chatbot";
//import { useLocation } from 'react-router-dom';
import Header from "./components/Header";
import "./App.css";
import { msalConfig } from "./authConfig";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import Login from "./Login";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import * as microsoftTeams from "@microsoft/teams-js";
import { acquireAccessToken, acquireUserProfile } from "./authUtil";
import { useMsal } from '@azure/msal-react';
import axios from "axios";
import logoutAndRedirect from '../src/components/logout'
import routetobot from './routetochatbot';
var logout
var notlogout

function App() {
  //  const { instance } = useMsal();
   
  //   // Get all accounts
  //   const accounts = instance.getAllAccounts();
  //   console.log("accountssss in app.js", accounts)
  //const navigate = useNavigate();

 //await refresh
  const [sso, setSso] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  const isInTeams = queryParams.get("msteams") === "true";
  const baseUrl = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ""
    }`;
  sessionStorage.setItem("swiss.api.baseUrl", baseUrl);
  // const location = useLocation()
  // console.log("inside app location", location
  // )
  const msalInstance = new PublicClientApplication(msalConfig);
 console.log("inside appppppp")
 //console.log("inside app  msaslinstnace", msalInstance)
  // }

  //const { accounts } = useMsal();
    //const user = accounts[0]
    //passwordssprstate = sessionStorage.getItem("passwordchange")
   // console.log("user details in app.js", user)
 


  //////////////
  // var passwordssprstate
  // var differenceInDays
  // var token = sessionStorage.getItem('swiss.api.access_token');
  // const { accounts } = useMsal();
  // const user = accounts[0] || sso;
  // passwordssprstate = sessionStorage.getItem("passwordchange")

  // async function getpasswordinformation() {
  //   let data = JSON.stringify({
  //     emailid: user.username
  
  //   });
  //   let config = {
  //     method: 'post',
  //     maxBodyLength: Infinity,
  //     url: `${baseUrl}/api/getpassworddetails?aa=bb`,
  //     headers: {
  //       'Content-Type': 'application/json',
  //       "authorization": `Bearer ${token}`
  //     },
  //     data: data
  //   };
  //   try {
  //     const response = await axios.request(config)
  //     console.log("Response from password", response)
  //     return response.data
  
  //   }
  //   catch (error) {
  //     console.log("Error while getting password details in chatbot.js file", error)
  //   }
  // }
  
  // async function calculateDaysDifference(inputTimestamp) {
  //   try {
  //     // Parse the input timestamp and extract only the date
  //     const inputDate = new Date(inputTimestamp.split("T")[0]);
  //     inputDate.setHours(0, 0, 0, 0)
  //     if (isNaN(inputDate.getTime())) {
  //       throw new Error("Invalid date format. Please provide a valid timestamp.");
  //     }
  
  //     // Get the current date and normalize it to only the date part
  //     const currentDate = new Date();
  //     console.log("current dateee", currentDate)
  //     currentDate.setHours(0, 0, 0, 0);
  
  //     // Calculate the difference in milliseconds and convert to days
  //     const differenceInMilliseconds = currentDate - inputDate;
  //     differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24)
  
  //     console.log(`Difference in days: ${differenceInDays}`);
  //     differenceInDays = 90 - differenceInDays
  //     console.log(`Password is going to expire in :${differenceInDays}`)
  //   } catch (error) {
  //     console.error(error.message);
  //   }
  // }
 
  // /////////////////
  // async function second(){
  //   //      const [sso, setSso] = useState(false);
  //   // const { accounts } = useMsal();
  //   if (passwordssprstate) {
  //     var responsefrompasswordinformation
    
  //     async function timewaiter (params) {
  //       console.log("inside timewaiter function")
  //       await new Promise((resolve)=> setTimeout(resolve, 10000))
  //       // setTimeout( async() => {
  //         responsefrompasswordinformation = await getpasswordinformation()
  //       //}, 4000);
       
        
  //    }
  //     await timewaiter()
    
  //     //const responsefrompasswordinformation = await getpasswordinformation()
  //     console.log("Responsee after user changed the password", responsefrompasswordinformation)
  //     await calculateDaysDifference(responsefrompasswordinformation)
  //     console.log("New password expiry", differenceInDays)
  //     if (differenceInDays === 90) {
  //       logout = true
  //       console.log("User changed the password using web and refreshed the page")
  //       // dbpasswordreset = "True"
  //       // await updatedb()
  //       console.log("Logoutttttttttt")
  //       sessionStorage.removeItem("passwordchange")
  //       await logoutAndRedirect(msalInstance)
  //     }
  //     else {
  //       notlogout = true
  //       console.log("Don't logout", notlogout)
        
  //       sessionStorage.removeItem("passwordchange")
  //       console.log("No password change and user refrsehed after changing the password ----")
  //       routetobot()
  //     }
    
  //   }
    
  //   }
  //   second()


  useEffect(() => {
    
    console.log("inside app useffect")
     //const urlParams = new URLSearchParams(location.search)
    //  const islogout = urlParams.get("logout")
    //  if(islogout){
    //   console.log("Logging out user", msalInstance)
    //   msalInstance.loginRedirect({
    //     postLogoutRedirecturi:"/"
    //   });

    //  }
    // const forcelogout = sessionStorage.getItem("forcelogout")
    // console.log("Force---------", forcelogout)
    // if(forcelogout === true)
    // {
    //   console.log("forcelogout is true")
    // }
    // else{
    //   console.log("forcelogout is not true")
   
 
  
    if (isInTeams) {
      console.log("Inside Teams SSO updated");
      try {
        microsoftTeams.initialize();
        microsoftTeams.app.initialize();
        microsoftTeams.appInitialization.notifyAppLoaded();
        microsoftTeams.appInitialization.notifySuccess();

        // Get Teams Context
        microsoftTeams.getContext(() => {
          const authTokenRequest = {
            successCallback: async (result) => {
              try {
                let data = await acquireAccessToken(result);
                let profile = await acquireUserProfile(data.access_token);
                setSso(true); // Teams SSO Successful
              } catch (err) {
                console.error("Error acquiring token or user profile: ", err);
              }
            },
            failureCallback: (err) => {
              console.error("Teams SSO Failed: ", err);
            },
          };
          microsoftTeams.authentication.getAuthToken(authTokenRequest);
        });
      } catch (error) {
        console.error("Teams SSO Initialization Error: ", error);
      }
    }
    
    
  }, [isInTeams,msalInstance]); // Empty dependency array ensures this runs once on mount
  
  return (
    <div>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              isInTeams ? (
                <>
                  <div className="App">
                    <Header />
                    <div className="warning-message">
                      <span>
                        Warning: Please note for Chat in MS Teams Apps, If you
                        click away from this chat, it will end your session...
                      </span>
                    </div>
                    <div className="app-container">
                      {sso ? (
                        <>
                          <Chatbot />
                        </>
                      ) : (
                        <p>Loading...</p>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <MsalProvider instance={msalInstance}>
                    <AuthenticatedTemplate>
                      <div className="App">
                        <Header />
                        <div className="app-container">
                          <Chatbot />
                        </div>
                      </div>
                    </AuthenticatedTemplate>
                    <UnauthenticatedTemplate>
                      <Login />
                    </UnauthenticatedTemplate>
                  </MsalProvider>
                </>
              )
            }
          />
          <Route
            path="/generate-report"
            element={<MsalProvider instance={msalInstance}>

              <AuthenticatedTemplate>

                <ReportPage />
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <Login />
              </UnauthenticatedTemplate>
            </MsalProvider>
            }
          />

        </Routes>
      </Router>
    </div>
  );
  
}

export default App;


/*
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ReportPage from './components/ReportPage';
import HomePage from './components/Header';
 
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/generate-report" element={<ReportPage />} />
      </Routes>
    </Router>
  );
}
 
export default App;
*/