// import { useMsal } from '@azure/msal-react';
// import {loginRequest} from '../authConfig'

// const logoutAndRedirect = async (msalInstance, navigate) => {
//     const { instance } = useMsal();
//     // console.log("inside logout function")
//     // console.log("msal instane in logout function", msalInstance)
//     // console.log("navigate in logout function", navigate)
//     // msalInstance.logoutRedirect({
//     //   postLogoutRedirectUri: "/", // Redirect to the root path after logout
//     // }).
// try{
// // sessionStorage.clear()
// // localStorage.clear()

// }catch(error) {
//       console.error("Logout failed:", error);
//     };
//   };
//   export default logoutAndRedirect

import React from 'react';
import { useMsal } from '@azure/msal-react';
import {loginRequest} from '../authConfig'
import * as microsoftTeams from "@microsoft/teams-js";
import * as msal from "@azure/msal-browser";


const logoutAndRedirect= async (instance) => {
    console.log("Logout function called")
    
    console.log("Logout function called instace", instance)
      


    try{
   
        console.log("Inside Logout.js")
        console.log("Process ", process.env.NODE_ENV);
        await instance.initialize();
        await instance.logoutRedirect({
                  postLogoutRedirectUri: "/", // Redirect to the root path after logout
                })
        // await instance.loginRedirect();
        // await instance.loginPopup(loginRequest).then(function(result){
        //     //window.location.href = document.referrer
        //     console.log("Poping uppppppppppp:::")
        // });
    
    }
    catch(error){
        console.error("Error inside logout.js", error)
    }    

   
};

export default logoutAndRedirect;